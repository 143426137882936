@import "../../style/mixins";

.wrapper {
  display: flex;
  gap: 15px;

  @include breakpoint(450px) {
    gap: 5px;
  }
  @include breakpoint(420px) {
    flex-direction: column;
  }

  .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #757575;
    @include breakpoint(500px) {
      font-size: 12px;
    }
  }
}
