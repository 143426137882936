.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;

  .text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #757575;
  }

  .block {
    display: flex;
    align-items: center;

    input {
      padding-left: 8px;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;

  textarea {
    overflow-y: scroll !important;
  }

  .selectList {
    display: flex;
    align-items: center;
    gap: 6px;

    .text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #757575;
    }
  }
}
