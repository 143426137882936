.wrapper {
  display: flex;
  gap: 8px;
  align-items: center;

  .number {
    color: #737373;
    font-size: 11px;
    font-weight: 400;
  }

  .block {
    width: 220px;
    height: 20px;
    background: #d9d9d9;

    @media screen and (max-width: 450px) {
      width: 180px;
      height: 13px;
    }

    .child {
      width: 10%;
      height: 20px;
      background: #57bb8a;
      @media screen and (max-width: 450px) {
        height: 13px;
      }
    }
  }
}
