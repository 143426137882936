.wrapper {
  width: 100%;
  margin-top: 20px;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #636b6f;
  }
  .dark {
    color: snow;
  }

  .container {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 15px;

    .leftBlock {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      .rating {
        font-size: 54px;
        font-weight: 400;
        color: #121212;
      }

      .stars {
        margin-top: -10px;
      }

      .count {
        margin-top: -5px;
        color: #898989;
        font-size: 12px;
      }
      .dark {
        color: snow;
      }
    }
    .ratingBlock {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
