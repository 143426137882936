.wrapper {
  margin-top: 20px;
  display: flex;
  min-width: 100%;
  overflow-x: scroll;
  padding-bottom: 5px;

  .image {
    .img {
      border-radius: 20px;
      width: 128px !important;
      padding: 5px;

      object-fit: contain;
    }
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(117, 117, 117, 0.62);
  }
}

.wrapper::-webkit-scrollbar {
  height: 7px;
  width: 40px;
}

.wrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: transparent;
}
