@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,  900&display=swap");

// tags
p,
a,
q,
s,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
tr,
th,
td,
em,
h1,
h2,
h3,
h4,
h5,
h6,
tt,
sub,
sup,
var,
nav,
pre,
del,
dfn,
img,
ins,
kbd,
div,
big,
span,
form,
menu,
ruby,
html,
body,
cite,
abbr,
code,
samp,
time,
mark,
audio,
video,
label,
tbody,
tfoot,
thead,
aside,
embed,
small,
table,
canvas,
legend,
strike,
strong,
figure,
center,
applet,
object,
iframe,
footer,
header,
hgroup,
output,
caption,
details,
article,
section,
summary,
acronym,
address,
fieldset,
figcaption,
blockquote {
  border: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 100%;
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
}

nav,
menu,
aside,
figure,
footer,
header,
hgroup,
details,
article,
section,
figcaption {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  opacity: 0.75;
  color: inherit;
}

ol,
ul {
  list-style: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

textarea {
  resize: none;
}

input {
  padding: 0;
}

img {
  user-select: none;
  pointer-events: none;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: "Roboto", sans-serif;
}

input {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;

  &[type="text"],
  &[type="number"],
  &[type="tel"],
  &[type="phone"],
  &[type="password"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px lightblue inset !important;
      -webkit-text-fill-color: black !important;
      transition: background-color 5000s ease-in-out 0s;
      -webkit-background-clip: text;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px lightblue inset !important;
    -webkit-text-fill-color: black !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-background-clip: text;
  }

  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

a,
button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
  background-color: transparent;
  font-size: inherit;
}

* {
  scroll-behavior: smooth !important;
}

body {
  font-family: "Roboto", sans-serif;
  position: relative;
  padding: 0;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  color: black;
  font-size: 16px;
  font-weight: 400;
  min-height: -webkit-fill-available;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 15px;
  }

  @media (max-width: 1256px) {
    ::-webkit-scrollbar {
      display: none !important;
    }
  }
}

