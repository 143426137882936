@import "../../../style/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .textField_block {
      position: relative;

      input {
        padding-right: 35px;
      }

      .icon {
        position: absolute;
        right: 8px;
        top: calc(50% - 5px);
        cursor: pointer;
      }
    }

    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #757575;

      @include breakpoint(500px) {
        font-size: 12px;
      }
    }

    .subLabel {
      font-size: 14px;
      color: #757575;
      @include breakpoint(500px) {
        font-size: 12px;
      }
    }

    .input_block {
      position: relative;

      .input {
        width: 100%;
        height: 36px;
        border: none;
        padding: 6px 37px 6px 6px;
        border-radius: 6px;
      }

      .icon {
        position: absolute;
        right: 8px;
        top: calc(50% - 10px);
        cursor: pointer;
      }
    }

    .input {
      width: 100%;
      height: 36px;
      border: none;
      padding: 6px;
      background: #f2f5f1;
      border-radius: 6px;
    }
  }

  .error {
    color: #cf3350;
    font-size: 12px;
  }
}

input {
  color: #757575 !important;
}
