.wrapper {
  width: 100%;
  background: #dee6e6;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-end;

    .btn {
      width: max-content;
    }
  }
  .content {
    width: 100%;
  }
}
