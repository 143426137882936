@import "../../../style/mixins";

.wrapper {
  display: flex;
  gap: 15px;

  span {
    color: #105b0c !important;
  }

  .text {
    font-size: 14px;
    line-height: 21px;
    color: #757575;
    font-weight: 300;

    @include breakpoint(450px) {
      font-size: 12px;
    }
  }
}
