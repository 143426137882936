.wrapper {
  width: 100%;
  margin-top: 20px;

  .name {
    font-size: 14px;
    color: snow;
    font-weight: 600;
  }

  .byId {
    width: 100%;
    margin-top: 20px;
    padding-left: 40px;

    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: #636b6f;
    }

    .container {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-top: 15px;

      .leftBlock {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;

        .rating {
          font-size: 64px;
          font-weight: 400;
          color: snow;
        }

        .stars {
          margin-top: -10px;
        }

        .count {
          margin-top: -5px;
          color: #898989;
          font-size: 12px;
        }
      }

      .ratingBlock {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
