.wrapper {
  width: 100%;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #636b6f;
  }

  .text {
    margin-top: 10px;
    font-size: 13px;
    color: #adadad;
    line-height: 18px;
  }
  .btn {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #105b0c;
    cursor: pointer;
  }
}
