.wrapper {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid gray;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #636b6f;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;

    .block {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .info {
        font-size: 14px;
        color: snow;
        font-weight: 400;
        line-height: 25px;
      }

      .date {
        font-size: 14px;
        font-weight: 300;
        color: #636b6f;
      }
    }
  }
}
