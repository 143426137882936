@import "../../style/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;

  .description {
    padding: 12px;
    background: #fff3cd;
    border: 1px solid #ffeeba;
    border-radius: 6px;

    .text {
      font-size: 14px;
      line-height: 21px;
      color: #856404;

      @include breakpoint(500px) {
        font-size: 12px;
      }
    }
  }

  .offer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .offerSubTitle {
      display: flex;
      align-items: center;

      .text {
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .switch {
        padding-left: 10px;
      }

      .block {
        width: 100%;
        display: flex;
        align-items: flex-end;
        visibility: visible;
        transition: 0.6s padding ease-in-out, 0.25s visibility ease-in-out;

        .icon {
          width: 42px;
          height: 36px;
          display: flex;
          align-items: center;
          background: #105b0c;
          position: relative;
          cursor: pointer;
          border-radius: 4px;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .disable {
        opacity: 0;
        height: 0;
        transition: 0.5s height ease-out, 0.1s opacity ease;
      }
    }
  }

  .switchs {
    padding-left: 10px;
  }

  .offerGeo {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .subtitle {
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
    }
    .text {
      font-size: 14px;
      color: #757575;
      @include breakpoint(500px) {
        font-size: 12px;
      }
    }

    .kesh {
      padding-left: 10px;
    }
  }
}
