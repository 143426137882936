@import "../../../style/mixins.scss";

.wrapper {
  width: 100%;
  display: flex;
  margin: 0 auto;
  @media (min-width: 2000px) {
    max-width: 1800px;
    display: grid;
    grid-template-columns: 200px auto;
  }
  .menu {
    width: 200px;
    position: fixed;
    top: 0;
    left: 0;
    max-height: 100vh;
    height: 100%;
    z-index: 9;

    @include breakpoint(1330px) {
      width: 90px;
    }

    @include breakpoint(535px) {
      width: 40px;
    }

    @include breakpoint(421px) {
      width: 30px;
    }

    @media (min-width: 2000px) {
      position: static;
    }
  }

  .main {
    max-width: calc(100% - 200px);
    width: 100%;
    padding: 0 20px 60px 20px;
    margin-left: 200px;
    margin-bottom: 40px;

    @include breakpoint(1330px) {
      max-width: calc(100% - 90px);
      margin-left: 90px;
    }
    @include breakpoint(421px) {
      max-width: calc(100% - 30px);
      margin-left: 40px !important;
    }

    @include breakpoint(535px) {
      max-width: calc(100% - 40px);
      margin-left: 40px;
      padding: 0 10px 60px 10px;
      margin-bottom: 20px;
    }

    @media (min-width: 2000px) {
      margin-left: 0;
      height: 100vh;
      overflow-y: auto;
    }
  }
}
