@import "../../../style/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .block {
    width: 100%;
  }

  .container {
    width: 100%;
    display: flex;
    gap: 20px;

    @include breakpoint(560px) {
      flex-direction: column;
    }
  }

  .btn {
    width: max-content;
  }
}
