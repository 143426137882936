@import "../../../style/mixins";

.wrapper {
  display: flex;
  gap: 20px;
  padding: 20px;
  border: 1px solid rgba(181, 178, 178, 0.72);
  position: relative;

  button {
    width: max-content;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  @include breakpoint(540px) {
    flex-direction: column;
    gap: 0;
  }
}
