.wrapper {
  width: 100%;
  height: 100vh;
  background: #dcd3d3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;

  .container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .text {
      color: #105b0c;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
