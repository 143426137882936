@import "../../style/mixins";

.wrapper {
  padding: 30px 10px;
  width: 100%;
  height: 100%;
  background: #dee6e6;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .block {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .title {
    font-size: 14px;
    padding: 12px 40px;
    color: rgba(0, 135, 95, 0.76);
    background: rgba(0, 135, 95, 0.28);
    border: 1px solid rgba(0, 135, 95, 0.43);
  }

  .text {
    font-size: 18px;
    color: #424242;
    font-weight: 600;
  }

  .select {
    max-width: 200px;
    width: 100%;
  }

  .container {
    display: flex;
    gap: 16px;
    align-items: center;

    @include breakpoint(1024px) {
      flex-direction: column;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .analytics {
    width: 100%;
    padding: 20px 0;
  }

  .detailed{
    width: 100%;
    padding: 20px 0;
  }
}
