@import "../../../style/mixins.scss";

.wrapper {
  display: flex;
  padding: 15px 0;
  background: #c2dbe8;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 6px;
  align-items: flex-start;

  & .isActive {
    border: 1px solid #4caf50;
  }

  &:hover {
    border: 1px solid #4caf50;
    transition: 0.3s border;
    background: rgba(76, 175, 80, 0.2);
  }

  .container {
    width: 100%;
    height: auto !important;
    padding: 0 15px;
    flex-basis: 0;
    flex-grow: 1;

    .image {
      object-fit: contain;
    }

    @include breakpoint(960px) {
      padding: 0 5px;
      height: 131px;
    }

    @media (min-width: 1600px) {
      height: 210px;
    }
    @media (min-width: 1800px) {
      height: 270px;
    }

    @media (min-width: 1921px) {
      height: 310px;
    }

    &:first-child {
      height: 124px;
      @media (min-width: 1600px) {
        height: 154px;
      }
      @media (min-width: 1800px) {
        height: 204px;
      }

      @include breakpoint(960px) {
        height: 82px;
      }
    }
  }
}

.isActive {
  border: 1px solid #4caf50;
  transition: 0.3s border;
  background: rgba(76, 175, 80, 0.2);
}

.skeleton {
  display: flex;
  gap: 30px;
}
