@import "../../../style/mixins.scss";

.wrapper {
  width: 100%;
  max-height: 600px;
  padding: 20px 0 50px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  top: 10px;

  @include breakpoint(1024px) {
    max-width: 600px;
  }

  @include breakpoint(850px) {
    max-width: 500px;
    max-height: 500px;
  }

  @include breakpoint(700px) {
    max-width: 400px;
  }
  @include breakpoint(520px) {
    max-width: 300px;
    max-height: 450px;
  }

  @include breakpoint(450px) {
    max-width: 280px;
    max-height: 400px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(117, 117, 117, 0.62);
  }

  .text {
    color: #757575;
  }

  .image {
    width: 100%;
    height: 400px;
  }
}
