.wrapper {
  width: 100%;
  height: 500px;

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding: 15px 0;
  }

  .block {
    background: #dee6e6;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .text {
      font-size: 18px;
      color: #424242;
      font-weight: 500;
    }

    button {
      width: max-content;
    }
  }
}
