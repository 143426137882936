@import "../../../style/mixins.scss";

.wrapper {
  width: 100%;
  background: #dee6e6;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-end;

    .btn {
      width: max-content;
    }
  }

  .content {
    width: 100%;
  }
  .block {
    width: 100%;
    display: flex;
    gap: 40px;
    padding-right: 100px;

    @include breakpoint(830px) {
      flex-direction: column;
    }

    @include breakpoint(1200px) {
      padding-right: 0;
    }

    @include breakpoint(1100px) {
      gap: 20px;
    }

    .select {
      width: 100%;
    }

    .phone_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .text {
        font-size: 18px;
        color: #757575;
        font-weight: 500;
      }

      .phone {
        width: 281px;
        position: sticky;
        top: 40px;

        @include breakpoint(830px) {
          position: static;
        }

        @include breakpoint(421px) {
          max-width: 300px;
          width: 100%;
        }
      }
    }
  }
}
