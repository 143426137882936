.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .head {
    display: flex;
    flex-direction: column;

    button {
      width: max-content;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
      padding: 15px 0;
    }
  }

  .container {
    background: #fff;

    padding: 15px;

    .info {
      font-size: 14px;
      color: #757575;
      font-weight: 400;
      line-height: 21px;

      .link {
        color: #00875f;
      }
    }

    .table {
      margin-top: 20px;
    }
  }
}
