@import "../../../style/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;

  div {
    padding: 0;
  }

  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #757575;

    @include breakpoint(500px) {
      font-size: 12px;
    }
  }
}
