.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .switch {
    padding-left: 10px;
  }

  .block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-end;
    visibility: visible;
    transition: 0.6s padding ease-in-out, 0.25s visibility ease-in-out;

    .form {
      display: flex;
      align-items: center;
      gap: 20px;

      button {
        width: max-content;
      }

      .formBlock {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }
  }

  .disable {
    opacity: 0;
    height: 0;
  }
}
