@import "../../../style/mixins";

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: #105b0c !important;
  }

  .label {
    font-size: 14px;
    color: #757575;
    @include breakpoint(500px) {
      font-size: 12px;
    }
  }
}
