@import "../../../style/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .text {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
  }

  .switch {
    padding-left: 10px;
  }

  .container {
    opacity: 1;
    transition: 0.6s padding ease-in-out, 0.25s opacity ease-in-out;

    .description {
      font-size: 14px;
      color: #757575;
      @include breakpoint(500px) {
        font-size: 12px;
      }
    }

    .optimization {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-left: 43px;
      opacity: 1;
      transition: 0.6s padding ease-in-out, 0.25s opacity ease-in-out;
      @include breakpoint(500px) {
        padding-left: 0;
      }

      .icons {
        display: flex;
        gap: 4px;
        align-items: center;

        .text {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #757575;
        }

        .icon {
          background: #105b0c;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          position: relative;
          cursor: pointer;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .block {
        display: flex;
        gap: 20px;
      }

      .info {
        padding: 10px;
        background: #105b0c;
        font-size: 14px;
        color: white;
        @include breakpoint(500px) {
          font-size: 12px;
        }
      }
    }

    .hidden {
      opacity: 0;
      height: 0;
      padding-left: 43px;
      transition: 0.5s height ease-out, 0.1s opacity ease;
    }
  }

  .disable {
    opacity: 0;
    height: 0;
    transition: 0.5s height ease-out, 0.1s opacity ease;

    .description {
      font-size: 14px;
      color: #757575;
      @include breakpoint(500px) {
        font-size: 12px;
      }
    }

    .optimization {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-left: 43px;
      visibility: visible;
      transition: visibility 0.6s ease-in-out, 0.25s ease-in-out;
      @include breakpoint(500px) {
        padding-left: 0;
      }

      .icons {
        display: flex;
        gap: 4px;
        align-items: center;

        .text {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #757575;
        }

        .icon {
          background: #105b0c;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          position: relative;
          cursor: pointer;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .block {
        display: flex;
        gap: 20px;
      }

      .info {
        padding: 10px;
        background: #105b0c;
        font-size: 14px;
        color: white;
        @include breakpoint(500px) {
          font-size: 12px;
        }
      }
    }

    .hidden {
      opacity: 0;
      height: 0;
      padding-left: 43px;
      transition: 0.5s height ease-out, 0.1s opacity ease;
    }
  }
}
