@import "../../../style/mixins";

.wrapper {
  width: 100%;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  background: #193118;

  .select {
    width: max-content;
    position: absolute;
    top: 20px;
    left: 160px;

    @include breakpoint(1024px) {
      left: 100px;
    }

    @include breakpoint(640px) {
      left: 50px;
    }

    @include breakpoint(400px) {
      left: 20px;
    }
  }
}

.container {
  //padding-right:  50px;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #f2f5f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
