.wrapper {
  display: flex;
  gap: 8px;
  align-items: center;

  .number {
    color: #737373;
    font-size: 11px;
    font-weight: 400;
  }

  .block {
    width: 130px;
    height: 8px;
    background: #d9d9d9;
    border-radius: 10px;

    .child {
      width: 10%;
      height: 8px;
      border-radius: 10px;
      background: #00875f;
    }
  }
}
