.wrapper {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;

  .name {
    font-size: 14px;
    color: snow;
  }

  .text {
    font-size: 14px;
    color: #adadad;
  }
}
