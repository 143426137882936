@import "../../../../style/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .tracking {
    max-width: calc(50% - 25px);
    width: 100%;

    @include breakpoint(850px) {
      max-width: 100%;
    }
  }

  .lists {
    display: flex;
    gap: 25px;

    @include breakpoint(850px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
  }
}
