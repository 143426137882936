.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #757575;
  }

  .error {
    margin-top: 5px;
    font-size: 13px;
    line-height: 18px;
    color: rgb(255, 0, 0);
  }
}
