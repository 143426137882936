.wrapper {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #636b6f;
  }

  .dark {
    color: snow;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .block {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .info {
        font-size: 14px;
        color: #000000;
        font-weight: 400;
        line-height: 25px;
      }
      .dark {
        color: snow;
      }

      .date {
        font-size: 14px;
        font-weight: 300;
        color: #636b6f;
      }
    }
  }
}
