.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .informationBlock {
    padding-bottom: 20px;

    .header {
      display: flex;
      gap: 25px;
      padding: 0 5px;

      .image {
        width: 70px;
        height: 70px;
        background: transparent;

        .img {
          border-radius: 20px;
        }
      }

      .block {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 5px;

        .name {
          font-size: 22px;
          color: #121212;
          font-weight: 700;
        }

        .dark {
          color: snow;
        }

        .info {
          display: flex;
          gap: 10px;

          .author {
            font-size: 13px;
            color: #047a56;
            font-weight: 700;
          }

          .category {
            font-size: 13px;
            color: #047a56;
            font-weight: 700;
          }
        }
      }
    }

    .container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, auto);

      .infoItem {
        display: flex;
        justify-content: center;
        border-right: 1px solid #ddd;

        &:last-child {
          border: none;
        }
      }
    }

    .btn {
      margin-top: 15px;
      background: #00875f;
    }
  }
}
