.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;

  .top {
    display: flex;
    gap: 3px;
    align-items: center;

    .title {
      font-size: 14px;
      color: #121212;
      font-weight: 700;
    }
    .dark {
      color: snow;
    }
  }

  .subTitle {
    font-size: 12px;
    color: #898989;
  }
}
