.wrapper {
  width: 100%;
  height: 574px;
  background-image: url("../../../assets/image/android.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 13px 10px;

  @media (max-width: 420px) {
    height: 514px;
    width: 250px;
    margin: 0 auto;
  }

  .container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 20px;

    .phoneContainer {
      width: 100%;
      padding: 10px 10px 0 10px;
    }

    .block {
      padding: 10px;

      .gallery {
        width: 100%;
      }
    }
  }

  .dark {
    background: #121212;
  }
}
