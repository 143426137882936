@import "../../../style/mixins";

.wrapper {
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .text {
      font-weight: 700;
      line-height: 21px;
      font-size: 26px;
    }

    .btn {
      width: 140px;
      @include breakpoint(400px) {
        width: max-content;
      }

      button {
        font-size: 14px;
      }
    }
  }
}
