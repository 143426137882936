@import "../../../style/mixins.scss";

.wrapper {
  padding: 10px;
  display: flex;
  gap: 6px;
  position: relative;
  cursor: pointer;

  .label {
    align-items: center;
    color: #ffffff;
    display: flex;
    gap: 6px;
    font-size: 14px;
  }
  .auth {
    align-items: center;
    color: #105b0c;
    display: flex;
    gap: 6px;
    font-size: 14px;
  }
}
