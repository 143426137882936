.wrapper {
  padding: 0 25px;
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: space-between;

  @media screen and (max-width: 660px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    gap: 15px;

    .img {
      width: 180px;
      height: 180px;

      @media screen and (max-width: 660px) {
        width: 70px;
        height: 70px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .name {
        font-size: 30px;
        color: snow;
      }

      .infoBlock {
        display: flex;
        gap: 20px;

        .category {
          font-size: 13px;
          color: #00875f;
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-end;
    @media screen and (max-width: 660px) {
      align-items: unset;
    }

    .top {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-end;

      .text {
        color: #8d8d8d;
        font-size: 13px;
      }
    }

    button {
      width: 240px;

      @media screen and (max-width: 920px) {
        width: 180px;
      }
      @media screen and (max-width: 660px) {
        min-width: 100% !important;
      }
    }
  }
}
