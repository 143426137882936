@import "../../../style/mixins.scss";

.wrapper {
  width: 100%;
  padding: 20px;
  background: #193118;
  height: 100%;

  .title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #ffffff;

    @include breakpoint(1330px) {
      display: none;
    }
  }

  .container {
    width: 100%;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0;
    @include breakpoint(1330px) {
      align-items: center;
    }

    .routs {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .block {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        @include breakpoint(1330px) {
          svg {
            width: 30px;
            height: 30px;
          }
        }

        @include breakpoint(500px) {
          svg {
            width: 20px;
            height: 20px;
          }
        }
        @include breakpoint(420px) {
          svg {
            width: 15px;
            height: 15px;
          }
        }

        .text {
          color: #ffffff;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;

          @include breakpoint(1330px) {
            display: none;
          }
        }
      }
    }

    .logout {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      @include breakpoint(1330px) {
        svg {
          width: 30px;
          height: 30px;
        }
      }
      @include breakpoint(420px) {
        svg {
          width: 15px;
          height: 15px;
        }
      }

      @include breakpoint(500px) {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .text {
        line-height: 21px;
        font-weight: 700;
        font-size: 14px;
        color: #ffffff;

        @include breakpoint(1330px) {
          display: none;
        }
      }
    }
  }
}
