@mixin breakpoint($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin svgFill($color, $size: false) {
  svg {
    @if $size {
      width: $size;
      height: $size;
    }

    path {
      transition: all 0.5s;
      fill: $color;
      @content;
    }
  }
}

@mixin scrollX {
  overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}
