@import "../../../style/mixins";

.wrapper {
  max-width: 320px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #105b0c;
  border-radius: 8px;
  font-size: 16px;
  color: snow;
  border: none;
  cursor: pointer;
  padding: 0 20px;
}

.small {
  height: 35px;
}
