@import "../../style/mixins";

.wrapper {
  max-width: 600px;
  width: 100%;

  .text {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    padding: 15px 0;
  }

  .forms {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .appName {
      max-width: 600px;
      width: 100%;
    }

    .container {
      max-width: 600px;
      padding: 15px 0;
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 30px;

      @include breakpoint(1024px) {
        grid-template-columns: auto;
      }
    }

    .rating {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      padding: 15px 0;
    }

    .textBlock {
      display: flex;
      gap: 4px;

      .comment {
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #757575;

        @include breakpoint(910px) {
          font-size: 14px;
        }
        @include breakpoint(880px) {
          font-size: 13px;
        }
        @include breakpoint(845px) {
          font-size: 12px;
        }
        @include breakpoint(830px) {
          font-size: 14px;
        }
      }

      .write {
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #105b0c;
        cursor: pointer;
        @include breakpoint(910px) {
          font-size: 14px;
        }
        @include breakpoint(880px) {
          font-size: 13px;
        }
        @include breakpoint(845px) {
          font-size: 12px;
        }
        @include breakpoint(830px) {
          font-size: 14px;
        }
      }
    }

    .rangeSlider {
      margin-top: 18px;
    }
  }
}
