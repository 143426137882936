.wrapper {
  width: 100%;

  .button {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;

    button {
      width: max-content;
    }
  }
}
