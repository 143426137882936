.container {
  width: 100%;
  grid-template-columns: repeat(3, auto);
  display: none;

  @media screen and (max-width: 660px) {
    display: grid;
  }

  .infoItem {
    display: flex;
    justify-content: center;
    border-right: 1px solid #ddd;

    .infoText {
      color: snow;
    }

    &:last-child {
      border: none;
    }
  }
}
