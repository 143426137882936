@import "../../../style/mixins";

.wrapper {
  width: max-content;
  background: white;
  margin: 50px auto;
  padding: 30px;

  @include breakpoint(560px) {
    padding: 15px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .text {
      font-size: 17px;
      font-weight: 600;
      line-height: 21px;
    }

    .icon {
      cursor: pointer;
    }
  }
}
