@import "../../../style/mixins.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .instruction {
    width: 100%;
    display: flex;
    border-radius: 8px;
    align-items: center;
    padding: 15px 20px;
    justify-content: space-between;
    background: rgba(255, 152, 0, 0.1);
    @include breakpoint(580px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    .text {
      width: 60%;
      line-height: 22px;
      color: #616161;
      @include breakpoint(580px) {
        width: 100%;
        font-size: 14px;
      }
    }
  }
}
