@import "../../../style/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .text {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
  }

  .description {
    padding: 10px;
    background: #105b0c;
    font-size: 14px;
    color: white;
    @include breakpoint(500px) {
      font-size: 12px;
    }
  }
  .switch {
    padding-left: 10px;
  }

  .visible {
    visibility: visible;
    transition: visibility 0.6s ease-in-out, 0.25s ease-in-out;
  }
  .hidden {
    visibility: hidden;
    height: 0;
    transition: 0.5s height ease-out, 0.1s opacity ease;
  }
}
