@import "../../../style/mixins.scss";

.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(80px, auto) minmax(80px, auto) minmax(
      80px,
      auto
    ) minmax(80px, auto);
  @include breakpoint(1024px) {
    grid-template-columns: minmax(120px, auto) minmax(120px, auto);
    grid-gap: 10px;
  }

  @include breakpoint(540px) {
    grid-template-columns: minmax(120px, auto);
  }

  .container {
    height: 75px;
    background: rgba(190, 182, 182, 0.93);
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    grid-column-gap: 12px;

    &.isActive {
      background: #105b0c;
      cursor: not-allowed;

      &:hover {
        background: #105b0c;
      }
    }

    &.isDisabled {
      background: rgba(190, 182, 182, 0.5);
      cursor: not-allowed;

      .number {
        background: #757575;
        opacity: 30%;
      }

      &:hover {
        background: rgba(190, 182, 182, 0.5);
      }
    }

    &:hover {
      background: #105b0c;
    }

    .number {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      background: #757575;
      border-radius: 50%;
    }

    .block {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .category {
        font-size: 18px;
        color: #ffffff;
      }

      .item {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
}

.comment {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(80px, auto) minmax(80px, auto) minmax(
      80px,
      auto
    ) minmax(80px, auto) minmax(80px, auto);

  @include breakpoint(1024px) {
    grid-template-columns: minmax(120px, auto) minmax(120px, auto);
    grid-gap: 10px;
  }

  @include breakpoint(540px) {
    grid-template-columns: minmax(120px, auto);
  }

  .container {
    height: 75px;
    background: rgba(190, 182, 182, 0.93);
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    grid-column-gap: 12px;

    &.isActive {
      background: #105b0c;
      cursor: not-allowed;

      &:hover {
        background: #105b0c;
      }
    }

    &.isDisabled {
      background: rgba(190, 182, 182, 0.5);
      cursor: not-allowed;

      .number {
        background: #757575;
        opacity: 30%;
      }

      &:hover {
        background: rgba(190, 182, 182, 0.5);
      }
    }

    &:hover {
      background: #105b0c;
    }

    .number {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      background: #757575;
      border-radius: 50%;
    }

    .block {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .category {
        font-size: 18px;
        color: #ffffff;
      }

      .item {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
}
