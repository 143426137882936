@import "../../../style/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    padding: 15px 0;
  }

  .container {
    width: 100%;
    display: flex;
    gap: 20px;

    @include breakpoint(800px) {
      flex-direction: column;
      gap: 0;
    }

    .date {
      width: 100%;
    }
  }

  .input {
    width: 100%;
    margin-top: 10px;
  }
}
