.wrapper {
  width: 100%;

  .title {
    font-size: 14px;
    color: #616161;
    padding: 15px 20px;
    background: rgba(76, 175, 80, 0.1);
    border-radius: 8px;
    transition: 0.3s all;
    opacity: 1;
  }

  .isTitle {
    font-size: 14px;
    color: #616161;
    background: rgba(76, 175, 80, 0.1);
    border-radius: 8px;
    opacity: 0;
    transition: 0.6s padding ease-in-out, 0.25s opacity ease-in-out;
    padding: 0 20px;
  }

  .domain {
    width: max-content;
    padding: 15px 0;
  }

  .isInstruction {
    opacity: 0;
    height: 0;
    transition: 0.5s height ease-out, 0.1s opacity ease;
  }

  .instruction {
    height: auto;
    opacity: 1;
    width: 100%;
    transition: 0.5s height ease, 0.2s opacity ease;
  }
}
