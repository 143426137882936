@import "../../../style/mixins";

.wrapper {
  height: max-content;
  padding: 30px 20px;
  background: gainsboro;
  max-width: 360px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;

  @include breakpoint(460px) {
    padding: 20px 15px;
    gap: 15px;
  }

  .title {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }

  .lists {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .text {
      color: #105b0c;
      font-size: 13px;
      cursor: pointer;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    .info {
      text-align: center;
      color: #757575;

      .signin {
        color: #105b0c;
        cursor: pointer;
      }
    }
  }
}
