@import "../../style/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .title {
      text-align: center;
      font-size: 100px;
      font-weight: bold;
      color: #ddd;
      @include breakpoint(500px) {
        font-size: 50px;
      }
    }

    .subtitle {
      text-align: center;
      font-weight: 400;
      font-size: 70px;
      color: #6f2dc0;
      @include breakpoint(500px) {
        font-size: 40px;
      }
    }
    .text {
      margin: 0 auto;
      max-width: 500px;
      font-size: 28px;
      text-align: center;
      color: #5ac8fa;
    }
  }
  button {
    height: 50px;
  }
}
