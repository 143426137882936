.wrapper {
  display: flex;
  flex-direction: column;
  gap: 7px;

  input {
    padding: 4px 0 5px 0;
  }

  .text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #757575;
  }
}
