@import "../../../style/mixins";

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  position: fixed;
  bottom: 0;
  z-index: 1;
  background: #e8e4e4;

  .text {
    width: 100%;
    text-align: center;
    color: rgba(117, 117, 117, 0.7);
    font-weight: 500;
  }
}
