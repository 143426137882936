.wrapper {
  max-width: 900px !important;
  width: 100%;

  .text {
    font-weight: 500;
    font-size: 14px;
    color: #757575;
    margin-top: 30px;
  }
}
