.wrapper {
  max-width: 100%;
  display: flex;
  gap: 6px;

  .image {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .block {
    width: 100%;
    display: flex;
    flex-direction: column;

    .titleBlock {
      display: flex;
      gap: 8px;

      .name {
        font-weight: 550;
        font-size: 12px;
        color: #4c4c4c;
      }
    }

    .date {
      font-weight: 400;
      font-size: 12px;
      color: #aaa;
    }

    .text {
      width: 100%;
      font-size: 13px;
      line-height: 21px;
      color: #636b6f;
      word-break: break-word;
    }

    .answer {
      margin-top: 10px;
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      background: #e5e5e5;

      .container {
        display: flex;
        gap: 10px;

        .name {
          font-weight: 550;
          font-size: 12px;
          color: #4c4c4c;
        }
      }
    }
  }
}
