@import "../../../style/mixins";

.icon {
  width: 35px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #105b0c;
  cursor: pointer;

  @include breakpoint(580px) {
    width: 25px;
    height: 28px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.tableRow:hover {
  background-color: rgba(117, 117, 117, 0.29);
}
