.wrapper {
  background: #636b6f;
  width: 100%;
  padding: 0 200px;

  @media screen and (max-width: 1250px) {
    padding: 0 100px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 50px;
  }
  @media screen and (max-width: 920px) {
    padding: 0;
  }

  .container {
    width: 100%;
    background: #000000c2;
    padding: 30px 60px;
    @media screen and (max-width: 840px) {
      padding: 10px 0;
    }

    .blcok {
      width: 100%;
      background: #121212;
      padding: 20px;
    }
  }
}
